
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as icons_45pageazEIgN5laEMeta } from "/workspace/frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue?macro=true";
import { default as indexUziJifzQ4yMeta } from "/workspace/frontend/pages/index.vue?macro=true";
import { default as manusUX08OvbF9eMeta } from "/workspace/frontend/pages/manus.vue?macro=true";
import { default as adminxCoVTGeC5hMeta } from "/workspace/frontend/pages/rom/[code]/admin.vue?macro=true";
import { default as indexOgu2ac4hUdMeta } from "/workspace/frontend/pages/rom/[code]/index.vue?macro=true";
import { default as index7V8cny1k0dMeta } from "/workspace/frontend/pages/rom/index.vue?macro=true";
import { default as startw0HyMkn9t8Meta } from "/workspace/frontend/pages/start.vue?macro=true";
import { default as component_45stubw32sGexcefMeta } from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubw32sGexcef } from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "icons-page___nb",
    path: "/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/workspace/frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "icons-page___nn",
    path: "/nn/_icons",
    meta: {"layout":"svg-sprite"},
    component: () => import("/workspace/frontend/node_modules/@nuxtjs/svg-sprite/dist/runtime/components/icons-page.vue")
  },
  {
    name: "index___nb",
    path: "/",
    component: () => import("/workspace/frontend/pages/index.vue")
  },
  {
    name: "index___nn",
    path: "/nn",
    component: () => import("/workspace/frontend/pages/index.vue")
  },
  {
    name: "manus___nb",
    path: "/manus",
    component: () => import("/workspace/frontend/pages/manus.vue")
  },
  {
    name: "manus___nn",
    path: "/nn/manus",
    component: () => import("/workspace/frontend/pages/manus.vue")
  },
  {
    name: "rom-code-admin___nb",
    path: "/rom/:code()/admin",
    meta: adminxCoVTGeC5hMeta || {},
    component: () => import("/workspace/frontend/pages/rom/[code]/admin.vue")
  },
  {
    name: "rom-code-admin___nn",
    path: "/nn/rom/:code()/admin",
    meta: adminxCoVTGeC5hMeta || {},
    component: () => import("/workspace/frontend/pages/rom/[code]/admin.vue")
  },
  {
    name: "rom-code___nb",
    path: "/rom/:code()",
    meta: indexOgu2ac4hUdMeta || {},
    component: () => import("/workspace/frontend/pages/rom/[code]/index.vue")
  },
  {
    name: "rom-code___nn",
    path: "/nn/rom/:code()",
    meta: indexOgu2ac4hUdMeta || {},
    component: () => import("/workspace/frontend/pages/rom/[code]/index.vue")
  },
  {
    name: "rom___nb",
    path: "/rom",
    component: () => import("/workspace/frontend/pages/rom/index.vue")
  },
  {
    name: "rom___nn",
    path: "/nn/rom",
    component: () => import("/workspace/frontend/pages/rom/index.vue")
  },
  {
    name: "start___nb",
    path: "/start",
    meta: startw0HyMkn9t8Meta || {},
    component: () => import("/workspace/frontend/pages/start.vue")
  },
  {
    name: "start___nn",
    path: "/nn/start",
    meta: startw0HyMkn9t8Meta || {},
    component: () => import("/workspace/frontend/pages/start.vue")
  },
  {
    name: component_45stubw32sGexcefMeta?.name,
    path: "/auth/login",
    component: component_45stubw32sGexcef
  },
  {
    name: component_45stubw32sGexcefMeta?.name,
    path: "/auth/logout",
    component: component_45stubw32sGexcef
  },
  {
    name: component_45stubw32sGexcefMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubw32sGexcef
  }
]