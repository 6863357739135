import { defineStore } from 'pinia';
import { v4 as uuidv4 } from 'uuid';
import { useStorage } from '@vueuse/core';

interface User {
    id: string;
    name: string;
}

export const useUserStore = defineStore('user', {
    state: (): User => ({
        id: '',
        name: 'Gjest'
    }),
    actions: {
        async init() {
            const { user: authUser } = useOidcAuth();
            let localuuid = null;
            if (typeof localStorage !== 'undefined') {
                localuuid = localStorage?.getItem('uuid');
            }
            const sessionUser = useStorage('user', {
                id: uuidv4(),
                name: 'Gjest'
            });

            this.$state.id =
                authUser.value?.providerInfo?.sub ?? localuuid ?? sessionUser.value.id ?? '';
            // authUser.value?.providerInfo?.name ?? sessionUser.value.name
            this.$state.name = 'Gjest';
        }
    }
});
