<template>
    <header class="sticky top-0 left-0 right-0 bg-vann text-white z-[20] shadow-lg">
        <div class="p-4 flex items-center justify-between">
            <a href="/" class="flex gap-4 items-center">
                <svg-icon name="logo" class="w-12 h-12" />
                <span class="font-heading4">Pengesmart</span>
            </a>
            <button class="btn" @click="open = !open">
                <span>Meny</span>
                <svg-icon
                    name="hamburger"
                    :class="[open && 'rotate-90', 'w-6 h-6 transition-transform']"
                />
            </button>
        </div>
        <nav
            :class="[
                !open && '-translate-y-4 opacity-0 pointer-events-none',
                'absolute top-full p-6 right-0 w-96 md:right-2 rounded-b-xl bg-vann transition-[opacity,transform] duration-300'
            ]"
            ref="nav"
        >
            <ul class="mx-auto flex flex-col gap-8 font-heading4">
                <li class="px-4">
                    <a :href="localePath('/manus')">{{ t('Les gjennom manuset') }}</a>
                </li>
                <li class="px-4">
                    <a :href="localePath('/rom')">{{ t('Start Pengesmart') }}</a>
                </li>
                <li class="px-4 border-t-2 border-vann-70 pt-8 font-body">
                    <span class="block">Språk</span>
                    <div class="flex gap-4 mt-2">
                        <button
                            class="btn"
                            :class="locale === 'nb' && 'active'"
                            @click="setLocale('nb')"
                        >
                            <span></span>
                            Bokmål
                        </button>
                        <button
                            class="btn"
                            :class="locale === 'nn' && 'active'"
                            @click="setLocale('nn')"
                        >
                            <span></span>
                            Nynorsk
                        </button>
                    </div>
                </li>
                <li class="px-4 mx-auto"></li>
            </ul>
        </nav>
    </header>
</template>

<script setup lang="ts">
const route = useRoute();
const open = ref(false);
const nav = ref();
const localePath = useLocalePath();
const { setLocale, locale, t } = useI18n();
watch(
    () => route.fullPath,
    () => {
        open.value = false;
    }
);
onClickOutside(nav, () => {
    open.value = false;
});
</script>

<style scoped>
button {
    @apply bg-white
        border-vann
        border-2
        text-vann
        flex
        items-center
        gap-2;
}

li button {
    @apply border-varm-grå;
}

li button span {
    @apply inline-block
        size-5
        rounded-full
        border-2
        border-varm-grå;
}

li button.active span {
    @apply border-vann
    relative;
}

li button.active span::after {
    content: '';
    @apply absolute
    size-3
    bg-vann
    rounded-full
    top-0.5
    left-0.5;
}
</style>
