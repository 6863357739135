import revive_payload_client_4sVQNw7RlN from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/workspace/frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/workspace/frontend/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/workspace/frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/workspace/frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/workspace/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/workspace/frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_5j40ijIZYu from "/workspace/frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/workspace/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/workspace/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/workspace/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import siteConfig_JRId4KOeUL from "/workspace/frontend/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/siteConfig.js";
import inferSeoMetaPlugin_JSh5nGhzCz from "/workspace/frontend/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/inferSeoMetaPlugin.js";
import titles_eoILE7jqvj from "/workspace/frontend/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/titles.js";
import defaultsWaitI18n_lla9AZgoHk from "/workspace/frontend/node_modules/nuxt-seo-utils/dist/runtime/app/plugins/defaultsWaitI18n.js";
import plugin_ybZ5mczHdl from "/workspace/frontend/node_modules/nuxt-socket-io/lib/plugin.js";
import plugin_Jozdw60ZsE from "/workspace/frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_nuxt3_gGdllsjxuZ from "/workspace/frontend/node_modules/nuxt-mail/dist/plugin-nuxt3.js";
import Vue3Lottie_client_bMZiOS6AB0 from "/workspace/frontend/plugins/Vue3Lottie.client.ts";
import chartjs_1wC9lAM0Tp from "/workspace/frontend/plugins/chartjs.ts";
import debounce_4Kl87r7kXF from "/workspace/frontend/plugins/debounce.ts";
import sentry_3AyO8nEfhE from "/workspace/frontend/plugins/sentry.ts";
import plugin_auto_pageviews_client_x3Y8XIaaPY from "/workspace/frontend/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
import ssg_detect_3fHkBxLtv0 from "/workspace/frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_5j40ijIZYu,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  siteConfig_JRId4KOeUL,
  inferSeoMetaPlugin_JSh5nGhzCz,
  titles_eoILE7jqvj,
  defaultsWaitI18n_lla9AZgoHk,
  plugin_ybZ5mczHdl,
  plugin_Jozdw60ZsE,
  plugin_nuxt3_gGdllsjxuZ,
  Vue3Lottie_client_bMZiOS6AB0,
  chartjs_1wC9lAM0Tp,
  debounce_4Kl87r7kXF,
  sentry_3AyO8nEfhE,
  plugin_auto_pageviews_client_x3Y8XIaaPY,
  ssg_detect_3fHkBxLtv0
]