import * as Sentry from '@sentry/vue';

const lazyLoadSentryIntegrations = async () => {
    // don't load on server
    if (!process.client) return;
    const { Replay } = await import('@sentry/vue');
    Sentry.addIntegration(
        new Replay({
            maskAllText: false,
            blockAllMedia: false
        })
    );
};

const getSentryIntegrations = () => {
    // don't load on server
    if (!process.client) return [];
    return [Sentry.browserTracingIntegration()];
};

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    setup: async nuxtApp => {
        const vueApp = nuxtApp.vueApp;
        const {
            public: { sentry }
        } = useRuntimeConfig();

        Sentry.init({
            enabled: process.env.NODE_ENV === 'production',
            app: vueApp,
            dsn: sentry.dsn ?? null,
            environment: sentry.environment ?? 'development',
            integrations: getSentryIntegrations(),
            // Performance Monitoring
            tracesSampleRate: 0.2, //  Capture 100% of the transactions
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            ignoreErrors: [
                // Random plugins/extensions
                'top.GLOBALS',
                // Facebook borked
                'fb_xd_fragment',
                "Can't find variable: _AutofillCallbackHandler"
            ],
            denyUrls: [
                // Facebook flakiness
                /graph\.facebook\.com/i,
                // Facebook blocked
                /connect\.facebook\.net\/en_US\/all\.js/i,
                // Chrome extensions
                /extensions\//i,
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i
            ]
        });

        // Lazy-load the replay integration to reduce bundle size
        lazyLoadSentryIntegrations();
    }
});
