<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
    error: Object as () => NuxtError
});

const handleError = () => clearError({ redirect: '/' });
</script>

<template>
    <div class="h-screen w-full flex flex-col bg-frost-30 relative">
        <navbar />
        <div class="grow flex items-center justify-center">
            <div class="bg-fjell p-6 rounded-lg text-white space-y-4">
                <h1>{{ error?.statusCode }}</h1>
                <h2 class="font-normal">{{ error?.message }}</h2>
                <button @click="handleError">Clear errors</button>
            </div>
        </div>
    </div>
</template>
