export default defineNuxtPlugin(() => {
	return {
		provide: {
			debounce: (fn: Function, delay: number): Function => {
				let timeout: any;

				return (...args: any[]) => {
					if (timeout) {
						clearTimeout(timeout);
					}

					timeout = setTimeout(() => {
						fn(...args);
					}, delay);
				};
			}
		}
	};
});
