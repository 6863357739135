
// @ts-nocheck
import locale__workspace_frontend_content_nb_messages_json from "../content/nb/messages.json";
import locale__workspace_frontend_content_nn_messages_json from "../content/nn/messages.json";


export const localeCodes =  [
  "nb",
  "nn"
]

export const localeLoaders = {
  "nb": [{ key: "../content/nb/messages.json", load: () => Promise.resolve(locale__workspace_frontend_content_nb_messages_json), cache: true }],
  "nn": [{ key: "../content/nn/messages.json", load: () => Promise.resolve(locale__workspace_frontend_content_nn_messages_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "nb",
      "iso": "nb-NO",
      "files": [
        "/workspace/frontend/content/nb/messages.json"
      ]
    },
    {
      "code": "nn",
      "iso": "nb-NO",
      "files": [
        "/workspace/frontend/content/nn/messages.json"
      ]
    }
  ],
  "defaultLocale": "nb",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": "locales",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "nb",
    "iso": "nb-NO",
    "files": [
      {
        "path": "/workspace/frontend/content/nb/messages.json"
      }
    ]
  },
  {
    "code": "nn",
    "iso": "nb-NO",
    "files": [
      {
        "path": "/workspace/frontend/content/nn/messages.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
